<template>
  <div class="text-center my-2" style="color: grey">
    <p v-show="loding">正在加载中···</p>
    <p v-show="!loding">已到底部</p>
  </div>
</template>

<script>
export default {
  name: 'ScrollLoding',
  props: {
    loding: {
      type: Boolean
    }
  }
}
</script>
