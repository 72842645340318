<template>
  <div class="article-footer text-subtitle-3">
    <span class="article-category hasBeforeIcon hasHoverUnderline" @click="toQueryCategory" v-if="Category">
        {{ Category.name }}
      </span>
    <span class="article-labels hasBeforeIcon" v-if="Labels.length">
        <span
          v-for="(label, index) in Labels"
          :key="label.id"
          class="hasHoverUnderline"
          @click="toQueryLabel(label.id)">
          {{ label.value }}{{ index === (Labels.length - 1) ? '' : ',' }}
        </span>
      </span>
  </div>
</template>

<script>
export default {
  name: 'CategoryAndLabelCard',
  props: {
    Category: {
      type: Object
    },
    Labels: {
      type: Array
    }
  },
  methods: {
    toQueryCategory () {
      this.$emit('toQueryCategory', this.Category.id)
    },

    toQueryLabel (labelId) {
      this.$emit('toQueryLabel', labelId)
    }
  }
}
</script>

<style lang="scss" scoped>
  .article-category {
    cursor: pointer;
    &::before {
      content: "\f009";
    }
  }
  .article-labels {
    cursor: pointer;
    &::before {
      content: "\f02b";
    }
  }
  .article-footer {
    // background: linear-gradient(to top,rgba(0,0,0,.04) 0,rgba(0,0,0,.02) 10px,rgba(0,0,0,.0) 30px);
    & > span {
      margin-right: .8rem;
    }
    .article-comment-count  {
      &::before {
        content: "\f0e6";
      }
    }
  }
</style>
